import uuid4 from "uuid4"

import { AnalyticsService } from "@finq/app-base/composables/services/analytics.service"

export default defineNuxtPlugin({
  name: "page-view",
  async setup(nuxtApp) {
    watch(
      nuxtApp.vueApp.$nuxt.$router.currentRoute,
      async (newRoute, oldRoute) => {
        if (!isSamePath(newRoute.path, oldRoute?.path)) {
          if (!isServer) {
            try {
              const pageViewId = uuid4()
              await AnalyticsService.reportPageView(pageViewId, newRoute)
            } catch (error) {
              console.error("Failed to report page view", error)
            }
          }
        }
      },
      { deep: true, immediate: true }
    )
  },
})

const isSamePath = (pathA: string, pathB = "") => {
  return !!pathA && !!pathB && (pathA === pathB || pathA === `${pathB}/` || pathB === `${pathA}/`)
}
